import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IContent, ISocialSection } from 'shared/models';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  public footerCallToAction = new BehaviorSubject<IContent>(null);
  private socialSection = new BehaviorSubject<ISocialSection>(null);

  setSocialSection(section: ISocialSection) {
    this.socialSection.next(section);
  }

  getSocialSection() {
    return this.socialSection.asObservable();
  }

  setFooterCallToAction(content: IContent) {
    this.footerCallToAction.next(content);
  }

  getFooterCallToAction() {
    return this.footerCallToAction.asObservable();
  }
}
