import { isPlatformBrowser } from '@angular/common';
import { Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { Analytics, logEvent } from '@angular/fire/analytics';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(
    private analytics: Analytics,
    private injector: Injector,
  ) {}
  logEvents(eventName: string, param?: { [key: string]: unknown }) {
    const platformId = this.injector.get(PLATFORM_ID);
    if (isPlatformBrowser(platformId)) {
      logEvent(this.analytics, eventName, param);
    }
  }
}
